import React from 'react'
import s from 'styled-components'

import Post from '../components/post'
import Layout from '../components/layout'
import { Text, TextSubheading } from '../data/typography'
const samText = (
  <Text>
    <TextSubheading>문화재 지정번호 : 사적 제134호</TextSubheading>
    <TextSubheading>지 정 일 시 : 1964년 6월</TextSubheading>
    <TextSubheading>소 재 지 : 제주특별자치도 제주시 삼성로22 (이도1동 1313)</TextSubheading>
    탐라 개국에 관하여 가장 널리 알려진 삼성 개국 신화는 고려사 지리지, 신증 동국여지승람, 탐라지, 영주지 등의 문헌에서 그 기록을 찾아볼 수 있다. 규장각에 소장되어 있는 영주지의 기록에 의하면 “영주에는 태초에 사람이 없었다.<br /><br />

    홀연히 세 신인이 한라산 북쪽 기슭에서 솟아 나왔는데 모흥(삼성혈)이라는 곳이다. 그들은 용모가 비범하고 도량이 활달하여 세상의 속됨이 없었다.<br /><br />

    하루는 한라산에 올라 멀리 보니 동해로부터 목함이 떠내려와 머물어 떠나지 않는 것을 보고 그 곳으로 내려가 목함을 열어보니 속에는 새알 모양의 옥함이 있고 자주빛 옷을 입고 허리띠를 찬 사람이 나왔다. 또 옥함을 여니 푸른 옷을 입은 처녀 세사람이 있었는데 모두 나이는 15,16세요, 용모는 품위 있고 아름답게 장식하여 앉아 있었다.<br /><br />

    또 망아지와 송아지, 오곡의 종자도 가지고 있었는데 이를 금당의 해안가에 내려 놓았다.세 신인은 모두 즐거워서 말하기를 이는 반드시 하늘이 리에게 주신 것이다 하였다. 사자는 두 번 절하고 엎드려 말하기를,”나는 동해의 벽랑국의 사자올시다.우리 임금님이 서쪽 바다의 기상을 바라보시더니 자주빛 기운이 하늘을 이어 상서로운 빛이 서리는 것을 보시고 신자 세사람이 절악에 내려와 있어서 장차 나라를 열고자 하나 배필이 없어 하고 있어 신더러 명하여 세 공주를 그 곳으로 데려가라 하였으니 좋도록 짝짓는 예를 올리시고 대업을 이룩하소서” 하고는 홀연 구름을 타고 사라져 버렸다.<br /><br />

    세 신인은 곧 목욕 재계하여 하늘에 고하고 나이 차례로 그녀들과 결혼하여 물 좋고 기름진 땅을 골라 이들은 형과 동생을 정하기 위하여 활을 쏘기로 했다. 활을 쏜 결과 고을나가 제일 멀리 쏘아 큰 형이 되고 그 다음 양을나,부을나순으로 정해졌다. 고양부 세 신인이 활을 쏘았던 곳이 제주시 화북동 조금 넘어간 곳인데, 이곳에 지금도 삼사석(三射石)이 남아 있다. 고을나가 사는 곳은 제 일도라 하였고, 양을나가 사는 곳은 제 이도라 하며, 부을나가 사는 곳은 제 삼도라 하였다.<br />
    이로부터 산업을 일으키기 시작하여 오곡의 씨를 뿌리고 송아지 망아지를 치니 날로 번성하여 부유하게 되어 드디어 인간 세계를 이룩하여 놓았다. 그 후 9백년이 지난 뒤에 민심이 모두 고씨에게 돌아 갔으므로 고씨를 왕으로 삼아 국호를 탁라라 하였다.삼성혈은 1964년 6월 10일 사적 제134호로 지정되었다.<br /><br />

    제주도의 고(髙)·양(梁)·부(夫)씨의 3시조 격인 고을나(髙乙那)·양을나(良乙那)·부을나(夫乙那) 세 신인(神人)이 각각 솟아났다는 신화가 전하는 유적으로 모흥혈(毛興穴)이라고도 한다.<br /><br />

    제례는 고·양·부 3성의 후손들이 매년 양력 4월 10일에 춘제(春祭), 10월 10일에 추제(秋祭)를 삼성전에서 지내는데 헌관은 3성이 돌아가며 맡고 있다.<br /><br />

    12월 10일에는 제주도민제로모시는 건시제(乾始祭)는 혈단(穴壇)에서 지내므로 혈제라고도 한다. 초헌관은 도지사, 아헌관과 종헌관은 기관장이나 유지 중에서 선임한다.<br /><br />

    삼신인이 솟아나왔다는 세 구멍은 품(品)자 형으로 나열되어있는데 그 중 하나는 둘레가 6자, 깊이는 바다까지 통한다고 하며 나머지 둘은 둘레가 각 3자이나 세월이 지남에 따라 지금은 흔적만 남았는데 상혈은 고을나, 좌혈은 양을나, 우혈은 부을나로 전하고 있다.<br /><br />

    주위에 수령 500여 년 이상의 노송들과 녹나무·조록나무 등 수십 종의 고목이 울창하게 서 있는데 나무들이 거의 이 구멍을 향하여 있어 신비로움을 더한다. 제주 고유의 건축물과 제사 모습 등이 남아 있는 유적지이며, 오랜 역사와 끊이지 않는 전통문화를 지닌 자긍심을 간직하고 있는 성소(聖所)이다.<br /><br />
  </Text>
)

const SamseonghyeolPage = () => (
  <Layout pageTitle={'역 사'} pageSubtitle={'삼성혈'}>
    <Post imageUrl={'/삼성혈.jpg'} text={samText} />
  </Layout>
)

export default SamseonghyeolPage
